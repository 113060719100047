<template>
  <div class="container">
    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />
    <div class="page__body">
      <div class="">
        <button
          class="
            button
            page__body__header__button
            ml-auto
            button--grey
            text--capital
          "
          type="button"
          data-toggle="modal"
          data-target="#newRiskModal"
          @click="clearForm"
          v-if="userCanCreateRiskLevel"
        >
          Add Risk Level <i class="ki ki-plus icon-sm ml-3"> </i>
        </button>
      </div>

      <div class="mt-5">
        <table class="table customtable customtable__templateid">
          <thead class="table__header">
            <tr class="table_header_text">
              <th style="width: 35%;">Name</th>
              <th>Days</th>
              <th>Provisioning Percentage(%)</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="table__text"
              v-for="item in riskLevels"
              v-bind:key="item.riskLevelKey"
            >
              <td style="width: 35%;">{{ item.riskName }}</td>
              <td>
                {{ item.arrears.riskArrearsFrom }} -
                {{ item.arrears.riskArrearsTo }} days
              </td>
              <td>{{ item.riskProvisioningPercentage }}</td>
              <td>
                <div class="dropdown">
                  <button
                    class="button btn-light dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    Action
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu-container"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      href="#"
                      class="dropdown-item"
                      type="button"
                      data-toggle="modal"
                      data-target="#editRiskModal"
                      v-on:click="getRiskLevel(item.riskLevelKey)"
                      v-if="userCanEditRiskLevel"
                      >Edit</a
                    >
                    <a
                      href="#"
                      class="dropdown-item"
                      type="button"
                      data-toggle="modal"
                      data-target="#deleteConfirmModal"
                      v-on:click="openConfirmation(item.riskLevelKey)"
                      v-if="userCanDeleteRiskLevel"
                      >Delete</a
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="pagination">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="text--capital text--regular pagination__text">
            row per page:
            <span
              class="d-inline-block text--primary"
              style="padding-right: 15px"
            >
              <select @change="changeLength" v-model="size">
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="30">30</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
              </select>
            </span>
            <span class="d-inline-block text--primary">{{
              !paginations.hasPreviousPage
                ? paginations.pageIndex
                : size * (paginations.pageIndex - 1) + 1
            }}</span>
            -
            <span class="d-inline-block text--primary">{{
              paginations.hasNextPage
                ? size * paginations.pageIndex
                : paginations.totalCount
            }}</span>
            of
            <span>{{ paginations ? paginations.totalCount : 0 }}</span>
          </div>
          <div
            class="
              pagination__buttons
              d-flex
              align-items-center
              justify-content-between
            "
            v-if="paginations"
          >
            <button
              class="button button--sm text--regular"
              @click="changePage('previous')"
              :disabled="!paginations.hasPreviousPage"
            >
              <ion-icon name="chevron-back-outline"></ion-icon>
            </button>
            <button
              v-for="page in paginations.totalPages"
              :key="page"
              :class="{
                'text--white button--primary ':
                  paginations.pageIndex === page,
              }"
              class="button text--regular button--sm ml-2"
              @click="changePage(page)"
            >
              {{ page }}
            </button>
            <button
              class="button text--regular button--sm ml-2"
              @click="changePage('next')"
              :disabled="!paginations.hasNextPage"
            >
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal modal__right" id="newRiskModal" aria-hidden="true">
      <div class="">
        <div class="modal__dialog" role="document">
          <div class="modal__content">
            <div class="modal__header">
              <ion-icon
                class="modal__head__icon modal__close modal__close--black"
                name="arrow-back-outline"
                data-dismiss="modal"
                aria-label="Close"
                id="formCloseButton"
                @click="clearForm"
                :disabled="isSaving"
              ></ion-icon>
              <div class="modal__header__title">
                <h5 class="modal__head__title">Store Risk Level</h5>
                <br />
              </div>
            </div>
            <div class="modal__body">
              <form class="form" @submit="saveForm">
                <div class="form__item validated">
                  <label class="form__label">Name</label>
                  <input
                    class="input form__input w-100"
                    v-model="form.riskName"
                    placeholder="Enter Name"
                    v-bind:class="{ 'input-required': err.riskName }"
                  />
                </div>

                <div class="row">
                  <div class="form__item col-md-6">
                    <label class="form__label">From (Days)</label>
                    <input
                      class="input form__input w-100"
                      type="number"
                      min="0"
                      v-model="form.riskArrearsFrom"
                      placeholder="Enter here"
                      v-bind:class="{ 'input-required': err.riskArrearsFrom }"
                    />
                  </div>

                  <div class="form__item col-md-6">
                    <label class="form__label">To (Days)</label>
                    <input
                      class="input form__input w-100"
                      type="number"
                      min="0"
                      v-model="form.riskArrearsTo"
                      placeholder="Enter here"
                      v-bind:class="{ 'input-required': err.riskArrearsTo }"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="form__item col-md-6">
                    <label class="form__label">Provision (%)</label>
                    <input
                      class="input form__input w-100"
                      type="number"
                      step=".01"
                      min="0"
                      v-model="form.riskProvisioningPercentage"
                      placeholder="Enter here"
                      v-bind:class="{
                        'input-required': err.riskProvisioningPercentage,
                      }"
                    />
                  </div>
                </div>

                <!-- <button class="btn form__button form__button--lg" type="button" disabled>
                  <span class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"></span>
                  <span class="sr-only">Loading...</span>
                </button> -->
                <button
                  class="button form__button form__button--lg"
                  type="submit"
                  v-if="!isSaving"
                >
                  Save Changes
                </button>
                <!-- loader button -->
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 save-changes"
                  v-if="isSaving"
                  :disabled="true"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  class="
                    button
                    form__action__text form__action__text--danger
                    ml-5
                  "
                  data-dismiss="modal"
                  aria-label="Close"
                  id="formCloseButton"
                  @click="clearForm"
                  :disabled="isSaving"
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->

    <!-- Modal -->
    <div class="modal modal__right" id="editRiskModal" aria-hidden="true">
      <div class="">
        <div class="modal__dialog" role="document">
          <div class="modal__content">
            <div class="modal__header">
              <ion-icon
                class="modal__head__icon modal__close modal__close--black"
                name="arrow-back-outline"
                data-dismiss="modal"
                aria-label="Close"
                id="formCloseButton2"
                @click="clearForm"
              ></ion-icon>
              <div class="modal__header__title">
                <h5 class="modal__head__title">Edit Risk Level</h5>
                <br />
              </div>
            </div>
            <div class="modal__body" v-if="riskLevel != null">
              <form class="form" @submit="saveEditForm">
                <div class="form__item">
                  <label class="form__label">Name</label>
                  <input
                    class="input form__input w-100"
                    v-model="form.riskName"
                    placeholder="Enter Name"
                    v-bind:class="{ 'input-required': err.riskName }"
                  />
                </div>

                <div class="row">
                  <div class="form__item col-md-6">
                    <label class="form__label">From (Days)</label>
                    <input
                      type="number"
                      min="0"
                      class="input form__input w-100"
                      v-model="form.riskArrearsFrom"
                      placeholder="Enter here"
                      v-bind:class="{ 'input-required': err.riskArrearsFrom }"
                    />
                  </div>

                  <div class="form__item col-md-6">
                    <label class="form__label">To (Days)</label>
                    <input
                      class="input form__input w-100"
                      type="number"
                      min="0"
                      v-model="form.riskArrearsTo"
                      placeholder="Enter here"
                      v-bind:class="{ 'input-required': err.riskArrearsTo }"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="form__item col-md-6">
                    <label class="form__label">Provision (%)</label>
                    <input
                      class="input form__input w-100"
                      type="number"
                      step=".01"
                      min="0"
                      v-model="form.riskProvisioningPercentage"
                      placeholder="Enter here"
                      v-bind:class="{
                        'input-required': err.riskProvisioningPercentage,
                      }"
                    />
                  </div>
                </div>

                <button
                  class="button form__button form__button--lg"
                  type="submit"
                  v-if="!isSaving"
                >
                  Save Changes
                </button>
                <!-- loader button -->
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 save-changes"
                  v-if="isSaving"
                  :disabled="true"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  class="
                    button
                    form__action__text form__action__text--danger
                    ml-5
                  "
                  data-dismiss="modal"
                  aria-label="Close"
                  :disabled="isSaving"
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->

    <!-- Modal -->
    <div
      class="modal modal__confirm"
      id="deleteConfirmModal"
      aria-hidden="true"
    >
      <ion-icon
        class="modal__head__icon modal__close"
        name="close-outline"
        data-dismiss="modal"
        aria-label="Close"
      ></ion-icon>
      <div class="">
        <div class="modal__dialog" role="document">
          <div class="modal__head">
            <div
              class="modal__close modal__head__icon modal__close--white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span> <ion-icon name="close-outline"></ion-icon> Close</span>
            </div>

            <h5 class="modal__header__title">Confirmation</h5>
          </div>
          <div class="modal__content">
            <div class="modal__header">
              <div class="modal__header__title">
                <h5 class="modal__head__title">
                  Are you sure you want to delete this Risk Level ?
                </h5>
              </div>
              <div class="modal__header__subtitle">
                <h6 class="my-4">
                  When you delete this Risk Level from your list, you won't be
                  able to access it again,
                </h6>
              </div>
            </div>

            <div>
              <button
                class="
                  button
                  form__button form__button--lg
                  mr-5
                  modal__confirm__button modal__confirm__button--cancel
                "
                data-dismiss="modal"
                aria-label="Close"
                id="closeButton"
              >
                Cancel
              </button>
              <a
                role="button"
                class="
                  button
                  form__button form__button--lg
                  w-25
                  modal__confirm__button modal__confirm__button--delete
                "
                v-if="isDeleting"
                :disabled="true"
              >
                <div class="spinner-border text-light" role="status"></div>
              </a>
              <button
                class="
                  button
                  form__button form__button--lg
                  modal__confirm__button modal__confirm__button--delete
                "
                type="submit"
                v-if="!isDeleting"
                v-on:click="deleteRiskLevel(deleteId)"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Role } from "@/core/services/role.js";
import { mapState } from "vuex";
import riskLevelService from "@/core/services/general-setup-service/risk-level.service.js";
export default Vue.extend({
  name: "risk-levels",
  data: function () {
    return {
      content_header: {
        title: "Risk Levels",
        subTitle: "",
        // subTitle: "Set up organization's risk levels.",
      },
      err: {
        riskName: false,
        riskArrearsFrom: false,
        riskArrearsTo: false,
        riskProvisioningPercentage: false,
      },
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      deleteId: null,
      isLoading: false,
      isDeleting: false,
      isSaving: false,
      loader: false,
      riskLevel: null,
      form: {
        riskName: null,
        riskArrearsFrom: 0,
        riskArrearsTo: 0,
        riskProvisioningPercentage: 0,
      },
      riskLevels: [],
      paginations: {},
      pages: 1,
      size: 20,
    };
  },
  methods: {
    async getRiskLevels() {
      try {
        this.isLoading = true;
        const res = await riskLevelService.get({ pageSize: this.size, pageIndex: this.pages });
        this.riskLevels = res.data.data.items;
        this.paginations = res.data.data;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    changePage(type) {
      if (type === "next") {
        this.pages++;
      } else if (type === "previous") {
        this.pages--;
      } else {
        this.pages = type;
      }

      this.getRiskLevels();
    },

    changeLength(e) {
      const { value } = e.target;
      this.size = parseInt(value);
      this.pages = 1;
      this.getRiskLevels();
    },
    async getRiskLevel(riskLevelKey) {
      try {
        this.riskLevel = null;
        this.isLoading = true;
        const res = await riskLevelService.getById(riskLevelKey);
        this.riskLevel = res.data.data;

        this.form.riskName = this.riskLevel.riskName;
        this.form.riskArrearsFrom = this.riskLevel.arrears.riskArrearsFrom;
        this.form.riskArrearsTo = this.riskLevel.arrears.riskArrearsTo;
        this.form.riskProvisioningPercentage =
          this.riskLevel.riskProvisioningPercentage;

        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    saveForm: async function (e) {
      e.preventDefault();
      if (this.validateForm()) {
        try {
          this.isSaving = true;
          await riskLevelService.create(this.form);
          this.getRiskLevels();
          document.getElementById("formCloseButton").click();
          this.clearForm();
          this.alertSuccess("New Risk level added");
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      }
    },
    saveEditForm: async function (e) {
      e.preventDefault();
      if (this.validateForm()) {
        try {
          this.isSaving = true;
          // console.log(this.riskLevel);
          this.form.riskLevelKey = this.riskLevel.riskLevelKey;
          await riskLevelService.update(this.riskLevel.riskLevelKey, this.form);
          this.getRiskLevels();
          document.getElementById("formCloseButton2").click();
          this.alertSuccess("Risk level updated");
          this.clearForm();
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      }
    },
    clearForm() {
      this.form = {
        riskName: null,
        riskArrearsFrom: 0,
        riskArrearsTo: 0,
        riskProvisioningPercentage: 0,
      };
      this.riskLevel = null;
    },
    async deleteRiskLevel(id) {
      this.isDeleting = true;
      try {
        await riskLevelService.delete(id);
        document.getElementById("closeButton").click();
        this.alertSuccess("Risk level deleted");
        this.isDeleting = false;
        this.getRiskLevels();
      } catch (e) {
        this.isDeleting = false;
        const error = e.response.data.message || "Something went wrong";
        this.alertError(error);
      }
    },
    openConfirmation(id) {
      this.deleteId = id;
    },
    validateForm() {
      this.clearFormError();
      if (
        this.form.riskName &&
        this.form.riskArrearsFrom >= 0 &&
        this.form.riskArrearsTo >= 0 &&
        this.form.riskProvisioningPercentage >= 0
      ) {
        return true;
      }

      if (!this.form.riskName) {
        this.err.riskName = true;
      }
      if (this.form.riskArrearsFrom < 0) {
        this.err.riskArrearsFrom = true;
      }
      if (this.form.riskArrearsTo < 0) {
        this.err.riskArrearsTo = true;
      }
      if (this.form.riskProvisioningPercentage < 0) {
        this.err.riskProvisioningPercentage = true;
      }
      return false;
    },
    clearFormError() {
      this.err = {
        riskName: false,
        riskArrearsFrom: false,
        riskArrearsTo: false,
        riskProvisioningPercentage: false,
      };
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
  },
  async created() {
    this.getRiskLevels();
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanCreateRiskLevel() {
      const RiskLevel = this.user.scopes.find(item => item.scopeName === Role.GeneralSetup);
      const { permissionsList } = RiskLevel;
      return permissionsList.CanCreateRiskLevel;
    },
    userCanEditRiskLevel() {
      const RiskLevel = this.user.scopes.find(item => item.scopeName === Role.GeneralSetup);
      const { permissionsList } = RiskLevel;
      return permissionsList.CanEditRiskLevel;
    },
    userCanDeleteRiskLevel() {
      const RiskLevel = this.user.scopes.find(item => item.scopeName === Role.GeneralSetup);
      const { permissionsList } = RiskLevel;
      return permissionsList.CanDeleteRiskLevel;
    },
  }
});
</script>
